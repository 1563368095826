
import {Vue, Component, Watch} from 'vue-property-decorator';
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import SayfaAciklama from "@/components/SayfaAciklama.vue";
import OzelAralikDatePicker from "@/components/infos/raporlama-ofispro/helper-comps/OzelAralikDatePicker.vue";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import {IBen} from "@/interface/IBen";
import {LisansEntity} from "@/entity/LisansEntity";

@Component({
  components: {OzelAralikDatePicker, SayfaAciklama, DeleteDialog}
})
export default class OfisPropUyarilar extends Vue {
  tab = 0;
  search: string = "";
  uyarilarItems:any= []
  tebligatBildirimleri:any=[]
  kesinlesmeDisabled:boolean = false;
  id= null
  secilenDosyalar = []
  menuItems = [
    {icon:'mdi-account-outline',title:'İş Ata'},
    {icon:'mdi-printer-outline',title:'Yazdır'},
    {icon:'mdi-download-outline',title:'İndir'},
    {icon:'mdi-arrow-right-bottom',title:'Taşı'},
  ]
  onFly = false;

  get tooltip(){
    let tooltip:string = ''
    if (this.lisanslar){
      tooltip = "Toplu Kesinleştirme  Talebi Özelliğini Kullanmak İçin E-İcraPro'ya Sahip Olmanız Lazım"
    }else{
      tooltip = 'Toplu Kesinleşme Talebi Gönder'

    }
    return tooltip
  }

  get lisanslar(){
    let kesinlestirme : boolean = false
    this.$store.getters.licenses.forEach((license:any) => {
      if (license.id == 3 && this.secilenDosyalar.length>0) {
        kesinlestirme = false
      }else {
        kesinlestirme = true
      }
    });
    return kesinlestirme
  }
  async allCreateTask(){
    let tasks: Array<Task> = [];


    this.secilenDosyalar.forEach((items:any) => {
      let borcluBilgileri:any = {
        adi:items.borclu.kisi?items.borclu.kisi.ad:null,
        soyadi:items.borclu.kisi?items.borclu.kisi.soyad:null,
        kurumAdi:items.borclu.kurum?items.borclu.kurum.sirket_adi:null
      }
      let borcluData

      if(items.borclu.kisi){
        borcluData = {id:items.borclu.id,borcluBilgileri:borcluBilgileri,
          tc:items.borclu.kisi.tc_kimlik_no,vergi_kimlik:null,mersis_no:null}
      }else{

        borcluData = {id:items.borclu.id,borcluBilgileri:borcluBilgileri,
          tc:null,vergi_kimlik:items.borclu.kurum.vergi_no ,mersis_no:items.borclu.kurum.mersis_no}
      }
      let task: Task = new Task();
      task.dosya_esas_no = items.borclu.takip.dosya_esas_no;
      task.birim_adi = items.borclu.takip.icra_dairesi.isim;
      task.dosya_id = (items.borclu.takip.id).toString();
      task.task_type_id = TaskTuru.toplu_genel_talep_hazirla;
      task.data = {
        params: {
          "hacizParams": {},
          "digerTalepParams": [
            {
              "grupKodu": 2,
              "talepKodu": 31,
              "talepAdi": "Takibin Kesinleşmesi",
              "talepKisaAdi": "Takibin Kesinleşmesi",
              "talepMasrafi": 0,
              "className": "AvukatTalepGenelTakibiKesinlestirDVO",
              "postaMasrafId": 0,
              "dosyaDurum": "A"
            }
          ]
        },
        borclu: borcluData,
        isKisiBool:items.borclu.kisi?true:false
      };
      tasks.push(task);

    })
    await this.$store.dispatch('postTasks', tasks)
        await this.$router.push(ProgramPaths.eicrapro + '/islemler')

  }


  bildirims(key:any){
    this.tebligatBildirimleri = this.bildirimShow.find((x:any) => x.key === key)
    return this.tebligatBildirimleri ? this.tebligatBildirimleri.length : 0;

  }

  get tebligatBildiirims(){

    let tebligatBildiirim = 0
    this.bildirimShow.forEach((bildirim:any)=>{
      tebligatBildiirim+=bildirim.length
    })
    return tebligatBildiirim
  }

  get bildirimShow(){
    const bildirimCount: any = [];
    Object.keys(this.uyarilarItems).forEach((key) => {
      const items = this.uyarilarItems[key]

      bildirimCount.push({key:key,length:items.length})
    });
    return bildirimCount;
  }

  handleItems(id:number){

    if (id!=4 && id!=1){
      this.kesinlesmeDisabled = false
    }
    if(id==2){
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'bila_tarihi')) {
        this.headers.splice(2, 0, { text: 'Bila Tarihi', value: 'bila_tarihi' });
        this.headers.splice(2, 0, { text: 'Talebin Gönderildiği Tarih', value: 'talebin_gonderildigi_tarih' });
      }

      this.tableItems=this.uyarilarItems.adres_arastirma_yapilacaklar_listesi

    }else if(id==3){
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'tebligat_gonderim_tarihi')) {
        this.headers.splice(2, 0, { text: 'Tebligat Gönderim Tarihi', value: 'tebligat_gonderim_tarihi' });
      }

      this.tableItems=this.uyarilarItems.barkod_sorgusu_yapilacaklar_ve_tebligat_bilgisi_girilecekler

    }else if(id==4){
      this.kesinlesmeDisabled = true

      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'kesainlesme_talebi_gonderim_tarihi')) {
        this.headers.splice(2, 0, { text: 'Kesinlesme Talebi Gönderim Tarihi', value: 'kesainlesme_talebi_gonderim_tarihi' });
      }

      this.tableItems=this.uyarilarItems.dosyanin_kesinlestirilmesi_talebi_g_d_l

    }else if(id==5){

      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'tarih')) {
        this.headers.splice(2, 0, { text: 'Takip Tarihi', value: 'tarih' });
      }

      this.tableItems = this.uyarilarItems.gonderildigi_tarih_girilecekler;

    }else if(id==6){

      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'kesinlesme_tarihi')) {
        this.headers.splice(2, 0, {text: 'Kesinleşme Tarihi', value: 'kesinlesme_tarihi'});
      }

      this.tableItems=this.uyarilarItems.haciz_talebinde_bulunulacak_dosyalar

    }else if(id==7){

      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'itiraz_tarihi')) {
        this.headers.splice(2, 0, {text: 'İtiraz Tarihi', value: 'itiraz_tarihi'});
      }

      this.tableItems=this.uyarilarItems.itirazin_iptali_kaldirilmasi_d_a_d
    }else if(id==8){

      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'kesinlesme_tarihi')) {
        this.headers.splice(2, 0, {text: 'Kesinleşme Tarihi', value: 'kesinlesme_tarihi'});
      }

      this.tableItems=this.uyarilarItems.mal_beyaninda_bulunmama_sebebiyle_d_a_d
    }else if(id==9){
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'bila_tarihi')) {
        this.headers.splice(2, 0, { text: 'Bila Tarihi', value: 'bila_tarihi' });
        this.headers.splice(2, 0, { text: 'Talebin Gönderildiği Tarih', value: 'talebin_gonderildigi_tarih' });
      }

      this.tableItems=this.uyarilarItems.mernis_adresine_tebligat_yapilacak_dosyalar
    }else if(id==10){
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'bila_tarihi')) {
        this.headers.splice(2, 0, { text: 'Bila Tarihi', value: 'bila_tarihi' });
        this.headers.splice(2, 0, { text: 'Talebin Gönderildiği Tarih', value: 'talebin_gonderildigi_tarih' });
      }
      this.tableItems=this.uyarilarItems.mersis_adresine_tebligat_yapilacak_dosyalar
    }else if(id==11){
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'bila_tarihi')) {
        this.headers.splice(2, 0, { text: 'Bila Tarihi', value: 'bila_tarihi' });
        this.headers.splice(2, 0, { text: 'Talebin Gönderildiği Tarih', value: 'talebin_gonderildigi_tarih' });
      }
      this.tableItems=this.uyarilarItems.otuzbes_gore_tebligat_talep_edilecek_dosyalar
    }else if(id==12){
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'bila_tarihi')) {
        this.headers.splice(2, 0, { text: 'Bila Tarihi', value: 'bila_tarihi' });
        this.headers.splice(2, 0, { text: 'Talebin Gönderildiği Tarih', value: 'talebin_gonderildigi_tarih' });
      }
      this.tableItems=this.uyarilarItems.yirmibir_gore_tebligat_talep_edilecek_dosyalar
    } else if(id==14){

      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'hacize_gidis_tarihi')) {
        this.headers.splice(2, 0, {text: 'Hacize Gidiş Tarihi', value: 'hacize_gidis_tarihi'});
      }

      this.tableItems=this.uyarilarItems.mal_beyaninda_bulunmama_sebebiyle_d_a_d
    }
    else if(id==15){

      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'talep_tarihi')) {
        this.headers.splice(2, 0, {text: 'Talep Tarihi', value: 'talep_tarihi'});
      }

      this.tableItems=this.uyarilarItems.mal_beyaninda_bulunmama_sebebiyle_d_a_d
    }
    else if(id==16){

      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'seksen_dokuz_bir_ihbarname_teblig_tarihi')) {
        this.headers.splice(2, 0, {text: "89-1'de İhbarnamenin Tebliğ Tarihi", value: 'seksen_dokuz_bir_ihbarname_teblig_tarihi'});
      }

      this.tableItems=this.uyarilarItems.mal_beyaninda_bulunmama_sebebiyle_d_a_d
    }else if(id==17){

      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'seksen_dokuz_iki_ihbarname_teblig_tarihi')) {
        this.headers.splice(2, 0, {text: "89-2'de İhbarnamenin Tebliğ Tarihi", value: 'seksen_dokuz_iki_ihbarname_teblig_tarihi'});
      }

      this.tableItems=this.uyarilarItems.mal_beyaninda_bulunmama_sebebiyle_d_a_d
    }else if(id==18){

      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'talebin_kabul_edildigi_tarihi')) {
        this.headers.splice(2, 0, {text: "Talebin Kabul Edildiği Tarihi", value: 'talebin_kabul_edildigi_tarihi'});
      }

      this.tableItems=this.uyarilarItems.mal_beyaninda_bulunmama_sebebiyle_d_a_d
    }else if(id==19){

      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'haciz_tarihi');

      if (!this.headers.some(header => header.value === 'muzakere_gonderildigi_tarih')) {
        this.headers.splice(2, 0, {text: "Müzakerenin Gönderildiği Tarih", value: 'muzakere_gonderildigi_tarih'});
      }

      this.tableItems=this.uyarilarItems.mal_beyaninda_bulunmama_sebebiyle_d_a_d
    }else if(id==20){

      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');

      if (!this.headers.some(header => header.value === 'fiilen_haciz_tarihi')) {
        this.headers.splice(2, 0, {text: "Fiilen Haciz Tarihi", value: 'fiilen_haciz_tarihi'});
      }

      this.tableItems=this.uyarilarItems.mal_beyaninda_bulunmama_sebebiyle_d_a_d
    }else if(id==21){

      this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'tarih');
      this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'hacize_gidis_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talep_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_bir_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'seksen_dokuz_iki_ihbarname_teblig_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'talebin_kabul_edildigi_tarihi');
      this.headers = this.headers.filter(header => header.value !== 'muzakere_gonderildigi_tarih');
      this.headers = this.headers.filter(header => header.value !== 'fiilen_haciz_tarihi');

      if (!this.headers.some(header => header.value === 'haciz_tarihi')) {
        this.headers.splice(2, 0, {text: "Haciz Tarihi", value: 'haciz_tarihi'});
      }

      this.tableItems=this.uyarilarItems.mal_beyaninda_bulunmama_sebebiyle_d_a_d
    }
  }

  mounted(){
    this.headers = this.headers.filter(header => header.value !== 'kesainlesme_talebi_gonderim_tarihi');
    this.headers = this.headers.filter(header => header.value !== 'bila_tarihi');
    this.headers = this.headers.filter(header => header.value !== 'kesinlesme_tarihi');
    this.headers = this.headers.filter(header => header.value !== 'tebligat_gonderim_tarihi');
    this.headers = this.headers.filter(header => header.value !== 'talebin_gonderildigi_tarih');
    this.headers = this.headers.filter(header => header.value !== 'itiraz_tarihi');
    this.$http.get('/api/v1/get-uyarilar').then(response =>{
      this.uyarilarItems=response;
      this.bildirims()
      this.tebligatBildiirims
    })
    this.lisanslar
  }


  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      class: 'my-header-style'
    },
    {text: 'İçerik', value: 'message',align:'start' },
    {text: 'Takip Tarihi', value: 'tarih'},
    {text: 'Kesinlesme Talebi Gönderim Tarihi', value: 'kesainlesme_talebi_gonderim_tarihi'},
    {text: 'Bila Tarihi', value: 'bila_tarihi'},
    {text: 'Tebligat Gönderim Tarihi', value: 'tebligat_gonderim_tarihi'},
    {text: 'Kesinleşme Tarihi', value: 'kesinlesme_tarihi'},
    {text: 'Talebin Gönderildiği Tarih', value: 'talebin_gonderildigi_tarih' },
    {text: 'İtiraz Tarihi', value: 'itiraz_tarihi'},
    {text: 'Hacize Gidiş Tarihi', value: 'hacize_gidis_tarihi'},
    {text: 'Talep Tarihi', value: 'talep_tarihi'},
    {text: "89-1'de İhbarnamenin Tebliğ Tarihi", value: 'seksen_dokuz_bir_ihbarname_teblig_tarihi'},
    {text: "89-2'de İhbarnamenin Tebliğ Tarihi", value: 'seksen_dokuz_iki_ihbarname_teblig_tarihi'},
    {text: "Talebin Kabul Edildiği Tarihi", value: 'talebin_kabul_edildigi_tarihi'},
    {text: "Müzakerenin Gönderildiği Tarih", value: 'muzakere_gonderildigi_tarih'},
    {text: "Fiilen Haciz Tarihi", value: 'fiilen_haciz_tarihi'},
    {text: "Haciz Tarihi", value: 'haciz_tarihi'},
    {text: 'İşlemler', value: 'actions',align: 'end'},
  ]

  tableItems:any = []

  items = [
    {id: 1, name: 'Tebligat Uyarıları',
      children: [
        {id: 2,key:'adres_arastirma_yapilacaklar_listesi', name: "Adres Araştırması Y."},
        {id: 3,key:'barkod_sorgusu_yapilacaklar_ve_tebligat_bilgisi_girilecekler', name: "Barkod Sorgusu ve Tebligat Bilgisi.G."},
        {id: 4,key:'dosyanin_kesinlestirilmesi_talebi_g_d_l', name: "Dosyanın Kesinleştirilmesi T.G."},
        {id: 5,key:'gonderildigi_tarih_girilecekler', name: "Gönderildiği Tarih G."},
        {id: 6,key:'haciz_talebinde_bulunulacak_dosyalar', name: "Haciz Talebinde B.D."},
        {id: 7,key:'itirazin_iptali_kaldirilmasi_d_a_d', name: "İtirazın İptali/Kaldırılması D.A.D"},
        {id: 8,key:'mal_beyaninda_bulunmama_sebebiyle_d_a_d', name: "Mal Beyanında Bulunmama Sebebiyle D.A.D"},
        {id: 9,key:'mernis_adresine_tebligat_yapilacak_dosyalar', name: "Mernis Adresine Tebligat Y.D"},
        {id: 10,key:'mersis_adresine_tebligat_yapilacak_dosyalar', name: "Mersis Adresine Tebligat Y.D"},
        {id: 11,key:'otuzbes_gore_tebligat_talep_edilecek_dosyalar', name: "35'e Göre Tebligat T.E.D"},
        {id: 12,key:'yirmibir_gore_tebligat_talep_edilecek_dosyalar', name: "21'e Göre Tebligat T.E.D"},
      ],
    },
   {
      id: 13,
      name: 'Haciz Uyarıları',
      children: [
        {id: 14,key:'', name: "Hacize Gidilecek D."},
        {id: 15,key:'', name: "Hacize Tarihi Girilecek D."},
        {id: 16,key:'', name: "89-2 İhbarnamesi Gönderilecekler"},
        {id: 17,key:'', name: "89-3 İhbarnamesi Gönderilecekler"},
        {id: 18,key:'', name: "Maaş Haczi Müzakersi Gönderilecekler"},
        {id: 19,key:'', name: "Müzakere Tebliği Kontrol Edilecekler"},
        {id: 20,key:'', name: "103 Davetiyesi Gönderilecekler"},
        {id: 21,key:'', name: "Kıymet Takdiri İstenecekler"},
      ],
    },
    /*
 {
   id: 15,
   name: 'Satış Uyarıları',
   children: [],
 },
 {
   id: 16,
   name: 'Satış Sonrası Uyarıları',
   children: [],
 },
 {
   id: 17,
   name: 'Manuel Uyarılar',
   children: [],
 }*/
  ]


  getTakipDetay(item:any){
    this.$router.push(ProgramPaths.icrapro + '/takip/' + item.takip_id);
  }
}
